
















































import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import { ssmMessageService } from "@/shared/services/message-service";
import etiquetaModule from "@/store/modules/etiqueta-module";
import { etiqueta } from "../../shared/dtos/etiqueta";
@Component({
  components: {}
})
export default class EtiquetasCombo extends Vue {
  @PropSync("modeloetiquetas", { default: [] }) model!: any;
  public async created() {
    await etiquetaModule.getetiquetas_from_tipo(2);
  }
  public activator = null;
  public attach = null;
  public editing = null;
  public index = -1;
  public menu = false;
  public search = null;

  public get Etiquetas() {
    return etiquetaModule.etiquetas_for_vuetify;
  }

  @Watch("model")
  public Wmodel(val: any, prev: any) {
    try {
      if (val.length === prev.length) return;
    } catch (error) {
      return;
    }

    this.model = val.map((v: any) => {
      if (typeof v === "string") {
        let eti: etiqueta = new etiqueta();
        eti.nombre = v;
        eti.sistema = false;
        eti.id_tipoetiqueta = 2;
        etiquetaModule.guardaretiqueta(eti);
        etiquetaModule.AddCaheEtiqueta({
          text: v,
          sistema: false
        });
        //Insert Api
        v = { text: v, sistema: false };
        ssmMessageService.toast("Etiqueta creada");
      }

      return v;
    });
  }

  public eliminar(index: any, item: any) {
    if (item.sistema === true) {
      ssmMessageService.toast("No se puede eliminar etiqueta del sistema");
      return;
    }
    if (!this.editing) {
      this.editing = item;
      this.index = index;
      for (let i = 0; i < etiquetaModule.etiquetas_for_vuetify.length; i++) {
        const element = etiquetaModule.etiquetas_for_vuetify[i];
        if (element.text === item.text) {
          let eti: etiqueta = new etiqueta();
          eti.id_tipoetiqueta = etiquetaModule.fromid;
          eti.id = element.id;
          etiquetaModule.eliminaretiqueta(eti);
          //etiquetaModule.DeleteCaheEtiqueta(i);
          //Delete Api
          ssmMessageService.toast("Eliminando etiqueta");
          break;
        }
      }
      this.editing = null;
      this.index = -1;
    } else {
      this.editing = null;
      this.index = -1;
    }
  }

  public filter(item: any, queryText: string, itemText: any) {
    if (item.header) return false;

    const hasValue = (val: any) => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text
        .toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    );
  }
}
