var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('label',[_vm._v("Selecciona una etiqueta o cree una escribiéndola y pulsando enter")]),_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.Etiquetas,"search-input":_vm.search,"hide-selected":"","multiple":"","small-chips":"","outlined":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v(" No se ha encontrado \" "),_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_vm._v(_vm._s(_vm.search))]),_vm._v("\". Pulsa "),_c('kbd',[_vm._v("enter")]),_vm._v(" para crear la etiqueta ")],1)])]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":"primary","input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","dark":"","label":"","small":""}},[_vm._v(_vm._s(item.text))]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.eliminar(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? "delete" : ""))])],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }